var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-base',{ref:"view",attrs:{"queries":_vm.queries},on:{"queries-response":_vm.onQueriesResponse},scopedSlots:_vm._u([{key:"skeleton",fn:function(){return [_c('card-list-skeleton',{attrs:{"item-count":3}}),_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-skeleton',{staticClass:"m-0",attrs:{"width":"30%","height":"36px"}})]},proxy:true}])})]},proxy:true}])},[_c('card',{attrs:{"title":_vm.$t('migrations_pending'),"icon":"cogs","no-body":""},scopedSlots:_vm._u([(_vm.pending)?{key:"header-buttons",fn:function(){return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.runMigrations}},[_c('icon',{attrs:{"iname":"play"}}),_vm._v(" "+_vm._s(_vm.$t('run'))+" ")],1)]},proxy:true}:null],null,true)},[(_vm.pending === null)?_c('b-card-body',[_c('span',{staticClass:"text-success"},[_c('icon',{attrs:{"iname":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t('migrations_no_pending'))+" ")],1)]):(_vm.pending)?_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.pending),function(ref){
var number = ref.number;
var description = ref.description;
var id = ref.id;
var disclaimer = ref.disclaimer;
return _c('b-list-group-item',{key:number},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(number)+". "+_vm._s(description)+" "),_c('div',{staticClass:"ml-auto"},[_c('b-button',{attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){return _vm.skipMigration(id)}}},[_c('icon',{attrs:{"iname":"close"}}),_vm._v(" "+_vm._s(_vm.$t('skip'))+" ")],1)],1)]),(disclaimer)?[_c('hr'),_c('p',{domProps:{"innerHTML":_vm._s(disclaimer)}}),_c('b-form-checkbox',{attrs:{"id":'checkbox-' + number,"name":'checkbox-' + number,"aria-describedby":'checkbox-feedback-' + number},model:{value:(_vm.checked[id]),callback:function ($$v) {_vm.$set(_vm.checked, id, $$v)},expression:"checked[id]"}},[_vm._v(" "+_vm._s(_vm.$t('migrations_disclaimer_check_message'))+" ")]),(_vm.checked[id] === false)?_c('b-form-invalid-feedback',{attrs:{"state":false,"id":'checkbox-feedback-' + number}},[_vm._v(" "+_vm._s(_vm.$t('migrations_disclaimer_not_checked'))+" ")]):_vm._e()]:_vm._e()],2)}),1):_vm._e()],1),_c('card',{attrs:{"title":_vm.$t('migrations_done'),"icon":"cogs","collapsable":"","collapsed":"","no-body":""}},[(_vm.done === null)?_c('b-card-body',[_c('span',{staticClass:"text-success"},[_c('icon',{attrs:{"iname":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t('migrations_no_done'))+" ")],1)]):(_vm.done)?_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.done),function(ref){
var number = ref.number;
var description = ref.description;
return _c('b-list-group-item',{key:number},[_vm._v(" "+_vm._s(number)+". "+_vm._s(description)+" ")])}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }